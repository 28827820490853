<template>
    <div id="pointsLoadingContainer">
        <div id="title">
            <div class="item"></div>
            <div class="circle item"></div>
        </div>
        <div id="bar" class="item"></div>
        <div id="visualInfo">
            <div id="visualInfo1">
                <div class="circle item"></div>
                <div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
            <div id="visualInfo2">
                <div class="circle item"></div>
                <div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
            <div id="visualInfo3">
                <div class="circle item"></div>
                <div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
            <div id="visualInfo4">
                <div class="item"></div>
            </div>
        </div>
        <div id="table">
            <div id="tableTitle">
                <div class="item"></div>
                <div class="item"></div>
            </div>
            <div id="tableContent">
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
                <div class="tableContentRow">
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item desktop"></div>
                    <div class="item desktop"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.circle {
    border-radius: 50%;
}
.item {
    background: #e6e6e6;
    position: relative;
    border-radius: 10px;
}
.item:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
    );
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
#pointsLoadingContainer {
    margin-top: 20px;
    #title {
        display: flex;
        > div:first-of-type {
            width: 70%;
        }
        .circle {
            width: 18px;
            height: 18px;
            margin-left: 10px;
        }
    }
    #bar {
        width: 100%;
        height: 5px;
        margin-top: 20px;
    }
    #visualInfo {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr 1fr;
        column-gap: 60px;
        row-gap: 40px;
        margin-top: 20px;
        padding: 20px;
        #visualInfo1,
        #visualInfo2,
        #visualInfo3 {
            display: flex;
            flex-direction: row;
            > .circle {
                width: 24px;
                height: 22px;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 10px;
            }
            > div:nth-child(2) {
                width: 100%;
                > div:first-of-type {
                    width: 100%;
                    height: 10px;
                }
                > div:nth-child(2) {
                    width: 50%;
                    height: 16px;
                    margin-top: 10px;
                }
            }
        }
        #visualInfo4 {
            display: flex;
            align-items: center;
            .item {
                width: 100%;
                height: 10px;
            }
        }
    }
    #table {
        padding: 20px;
        > #tableTitle {
            display: flex;
            align-items: center;
            > .item {
                width: 60px;
                height: 10px;
                &:nth-child(2) {
                    margin-left: 60px;
                    width: 80px;
                }
            }
        }
        > #tableContent {
            padding: 20px;
            > .tableContentRow {
                display: flex;
                align-items: center;
                padding: 20px;
                > .item {
                    width: 60px;
                    height: 10px;
                    @media screen and (min-width: 1000px) {
                        margin-right: 25px;
                    }
                    &:nth-child(2) {
                        margin-left: 60px;
                        width: 100%;
                        @media screen and (min-width: 1000px) {
                            margin-right: 25px;
                            margin-left: 0px;
                            width: 200px;
                        }
                    }
                    &.desktop{
                        display: none;
                        @media screen and (min-width: 1000px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
</style>