
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import PointsHistoryTable from '@/components/PointsHistoryTable.vue'
import PointsInfo from '@/components/PointsInfo.vue'
import PointsLoadingMobile from '@/components/PointsLoadingMobile.vue'
import NoPoints from '@/components/NoPoints.vue'

export default defineComponent({
    components: { PointsHistoryTable, PointsInfo, PointsLoadingMobile, NoPoints },
    setup() {
        const recordStore: any = inject('recordStore')
        const authStore: any = inject('authState');
        onBeforeMount(()=>{
            authStore.getUserDetails();
        })
        return {
            loading: computed(() => recordStore.state.recordState.loading),
            recordStore,
        }
    },
})
